




import { defineComponent, onMounted, ref } from "@vue/composition-api"
import QRCode from "qrcode"

export default defineComponent({
    props: {
        value: {
            type: String,
            required: true,
        },
        width: Number,
    },
    setup(props) {
        const qrcodeCanvas = ref<HTMLCanvasElement | null>(null)
        onMounted(() => {
            QRCode.toCanvas(qrcodeCanvas.value!, props.value, {
                margin: 1,
                width: props.width,
            })
        })
        return {
            qrcodeCanvas,
        }
    },
})
